<template>
  <div>
    <FilterOverview title="RM Reisebasis" :showFilterInitially="false">
      <template #toolbar-left>
        <b-button-group size="sm" style="width: 200px" class="mr-4 mt-4 mb-3 ml-2">
          <b-button
            v-for="reisebasis in reisebasisSites"
            :key="reisebasis"
            :id="reisebasis"
            :variant="reisebasis === 'Reisebasis' ? 'primary' : ''"
            class="mr-1"
            :to="'/reisetermine/fvc/rm-' + reisebasis.toLowerCase()"
            style="width: 33%"
          >
            {{ reisebasis }}
          </b-button>
        </b-button-group>
        <b-button-group size="sm" style="width: 100px" class="mr-4 mt-4 mb-3 ml-2">
          <b-button
            v-for="regionFilter in regionFilters"
            :key="regionFilter"
            :id="regionFilter"
            @click="regionFilterClicked"
            :variant="regionFilter === regionFilterSelected ? 'primary' : ''"
            class="mr-1"
            style="width: 50%"
          >
            {{ regionFilter }}
          </b-button>
        </b-button-group>
        <b-button-group size="sm" class="mt-4 mb-3">
          <b-button
            v-for="deutscherAbflughafen in deutscheAbflughaefen"
            :key="deutscherAbflughafen"
            :id="deutscherAbflughafen"
            @click="onDeutscherAbflughafenFilterClick"
            :variant="deutscheAbflughaefenSelected.includes(deutscherAbflughafen) ? 'primary' : ''"
            class="mr-1"
            style="width: 33%"
          >
            {{ deutscherAbflughafen }}
          </b-button>
        </b-button-group>
      </template>
      <template #table="{ tableHeight }">
        <ag-grid-vue
          :rowData="rowData"
          :style="{ height: tableHeight + 80 + 'px' }"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :suppressAggFuncInHeader="true"
          :rowGroupPanelShow="rowGroupPanelShow"
          :animateRows="animateRows"
          :autoGroupColumnDef="{
            headerName: 'Gruppe',
            pinned: 'left', //force pinned left. Does not work in columnDef
            width: 150,
          }"
          class="ag-theme-alpine m-0 p-0"
          @grid-ready="onGridReady"
          @rowClicked="onRowClick"
        ></ag-grid-vue>
      </template>
    </FilterOverview>
  </div>
</template>
<script>
import FilterOverview from '@/components/common/filter-overview.vue';
import { GET_ALL_DATA, GET_CONVERSIONS } from '@/core/flugverfuegbarkeit/stores/rm-reisebasis.module';
import { mapGetters } from 'vuex';
import { getRegionsmanagerById } from '@/core/common/helpers/utils.js';
import { columnDefsYieldRMReisebasis } from '@/core/flugverfuegbarkeit/yield-rm-reisebasis-table-config.js';
import { AgGridVue } from 'ag-grid-vue';
import 'ag-grid-enterprise';
import CellRendererPrioritaet from '@/components/flugverfuegbarkeit/cell-renderer-prioritaet.vue';
import CustomHeader from '@/components/flugverfuegbarkeit/custom-header.vue';

export default {
  components: {
    FilterOverview,
    AgGridVue,
    CellRendererPrioritaet,
    agColumnHeader: CustomHeader,
  },

  data() {
    return {
      defaultColDef: {
        suppressMenu: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        filterParams: {
          defaultToNothingSelected: true,
        },
      },
      reisebasisSites: ['Reisebasis', 'Historie'],
      rowData: null,
      columnDefs: null,
      animateRows: true,
      regionFilters: ['EU', 'Fern'],
      regionFilterSelected: null,

      rowGroupPanelShow: 'always',
      deutscheAbflughaefen: ['Alle', 'FRA', 'MUC', 'HAM', 'DUS', 'BER', 'VIE', 'ZRH'],
      deutscheAbflughaefenSelected: ['FRA'],
      fernRegions: ['Asien', 'Amerika', 'Ozeanien', 'Afrika', 'Orient'],
      euRegions: ['Südeuropa', 'Nordeuropa', 'Dach'],
    };
  },
  created() {
    this.columnDefs = columnDefsYieldRMReisebasis;
    this.rowModelType = 'clientSide';
  },
  computed: {
    ...mapGetters(['getData', 'currentUserId', 'getFormattedData', 'getRegionsmanagerConfig']),
    alleReisenFromConfig() {
      return Object.values(this.getRegionsmanagerConfig)?.flat();
    },
    alleReisen() {
      if (this.getData.length > 0) {
        return [...new Set(this.getData.map(item => item.reisekuerzel).flat())];
      } else return [];
    },
    getDataForRMReisebasis() {
      if (
        this.deutscheAbflughaefenSelected.length === 1 &&
        this.deutscheAbflughaefenSelected.includes('Alle')
      ) {
        return this.getFormattedData;
      }
      return this.getFormattedData
        .filter(row => this.deutscheAbflughaefenSelected.includes(row.abfh))
        .filter(row => {
          if (!this.regionFilterSelected) return true;

          const regionFilters = {
            EU: this.euRegions.includes(row.region),
            Fern: this.fernRegions.includes(row.region),
          };

          return regionFilters[this.regionFilterSelected] ?? true;
        });
    },
  },
  methods: {
    getCount(array) {
      return array.map(item => item.count).reduce((acc, curr) => curr + acc, 0);
    },
    async onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      await Promise.all([
        this.$store.dispatch(GET_ALL_DATA),
        this.$store.dispatch(GET_CONVERSIONS, this.alleReisenFromConfig),
      ]);
      this.gridApi.setRowData(this.getDataForRMReisebasis);
      this.checkRegionsmanagerLoggedIn();
    },
    checkRegionsmanagerLoggedIn() {
      const regionsmanagerFromConfig = getRegionsmanagerById(this.currentUserId);
      if (regionsmanagerFromConfig?.shortname) {
        this.gridApi.forEachNode(node => {
          if (
            node.parent?.key === regionsmanagerFromConfig.shortname &&
            ['0', '1', '2', '3'].includes(node.key)
          ) {
            this.gridApi.setRowNodeExpanded(node, true, true);
          }
        });
      }
    },
    onRowClick($event) {
      const { data } = $event;
      if (data) {
        const routeData = this.$router.resolve({
          path: '/reisetermine/fvc/overview',
          query: { reise: data.reise },
        });
        window.open(routeData.href, '_blank');
      }
    },
    onDeutscherAbflughafenFilterClick($event) {
      const { id: abflughafen } = $event.target;

      if (abflughafen === 'Alle') {
        this.deutscheAbflughaefenSelected = ['Alle'];
      }
      // ABFH ist in Liste
      else if (this.deutscheAbflughaefenSelected.includes(abflughafen)) {
        if (this.deutscheAbflughaefenSelected.length === 1) {
          this.deutscheAbflughaefenSelected = ['Alle'];
        } else {
          this.deutscheAbflughaefenSelected = this.deutscheAbflughaefenSelected.filter(
            abfh => abfh !== abflughafen
          );
        }
      }
      // ABFH nicht in Liste
      else if (!this.deutscheAbflughaefenSelected.includes(abflughafen)) {
        if (this.deutscheAbflughaefenSelected.length === this.deutscheAbflughaefen.length - 2) {
          this.deutscheAbflughaefenSelected = ['Alle'];
        } else if (
          this.deutscheAbflughaefenSelected.length === 1 &&
          this.deutscheAbflughaefenSelected.includes('Alle')
        ) {
          this.deutscheAbflughaefenSelected = [abflughafen];
        } else {
          this.deutscheAbflughaefenSelected = [...this.deutscheAbflughaefenSelected, abflughafen];
        }
      }
      this.gridApi.setRowData(this.getDataForRMReisebasis);
    },
    regionFilterClicked($event) {
      const region = $event.target.id;
      if (this.regionFilterSelected === null || this.regionFilterSelected !== region) {
        this.regionFilterSelected = region;
      } else if (this.regionFilterSelected === region) {
        this.regionFilterSelected = null;
      }

      this.gridApi.setRowData(this.getDataForRMReisebasis);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-alpine.css';
:deep(.ag-theme-alpine) {
  font-family: 'Poppins';
}
:deep(.ag-theme-alpine .ag-header-cell) {
  font-weight: 400;
  font-size: 14px;
}
:deep(.ag-row-group > div) {
  background-color: rgb(190, 190, 190, 0.2) !important;
}
:deep(.ag-header-cell),
:deep(.ag-header-group-cell) {
  padding-left: 8px;
  padding-right: 8px;
}
</style>
